import { useRollbar } from '@rollbar/react';
import { useQuery } from '@tanstack/react-query';

import { GET_EMPLOYER_STATS } from 'src/modules/employer/constants/keys';
import axios from 'src/utils/axios';
import { employerStatsValidator } from 'src/utils/validators/employer-stats-validator';

export const useGetEmployerStats = (employerId: number) => {
  const rollbar = useRollbar();

  return useQuery({
    queryKey: [GET_EMPLOYER_STATS, employerId],
    queryFn: async () => {
      const response = await axios.get(`/employers/${employerId}/stats`);
      const validated = await employerStatsValidator.parseAsync(response.data);

      return validated;
    },
    onError: error => {
      rollbar.error(error as unknown as Error);
    },
  });
};
