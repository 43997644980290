import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogUpdateAction,
  CatalogUpdateActionFail,
  CatalogUpdateActionRequest,
  CatalogUpdateActionReset,
  CatalogUpdateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import { LimitFormData } from 'src/types/limit';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface UpdateCatalogData {
  name: string;
  is_filtered_by_country: boolean;
  categories: {
    id: string | number;
    max: number | string;
    name: string;
  }[];
  limits: LimitFormData[];
}

export const updateCatalog =
  (
    catalogId: string | number,
    catalogData: UpdateCatalogData,
  ): ThunkAction<Promise<void>, RootState, undefined, CatalogUpdateAction> =>
  async dispatch => {
    dispatch<CatalogUpdateActionRequest>({
      type: ActionTypes.CATALOG_UPDATE_REQUEST,
    });

    try {
      const res = await axios.put<Response<Catalog>>(
        `${apiConfig.url}/catalogs/${catalogId}`,
        {
          ...catalogData,
          limits: catalogData.limits.map(limit => ({
            ...limit,
            category_ids: limit.categories.map(c => c.id),
          })),
        },
      );

      const { data } = res.data;

      dispatch<CatalogUpdateActionSuccess>({
        type: ActionTypes.CATALOG_UPDATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Catalog successfully updated', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<CatalogUpdateActionFail>({
        type: ActionTypes.CATALOG_UPDATE_FAIL,
        payload: 'Unable to update catalog',
      });

      enqueueSnackbar('Unable to update catalog', {
        variant: 'error',
      });
    }
  };

export const updateCatalogReset = (): CatalogUpdateActionReset => ({
  type: ActionTypes.CATALOG_UPDATE_RESET,
});
