import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogCreateAction,
  CatalogCreateActionFail,
  CatalogCreateActionRequest,
  CatalogCreateActionReset,
  CatalogCreateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import { LimitFormData } from 'src/types/limit';
import { Response } from 'src/types/response';
import axios, { ErrorResponse } from 'src/utils/axios';

interface CreateCatalogParams {
  name: string;
  is_filtered_by_country: boolean;
  limits: LimitFormData[];
}

export const createCatalog =
  (
    catalogData: CreateCatalogParams,
  ): ThunkAction<Promise<void>, RootState, undefined, CatalogCreateAction> =>
  async dispatch => {
    dispatch<CatalogCreateActionRequest>({
      type: ActionTypes.CATALOG_CREATE_REQUEST,
    });

    try {
      const res = await axios.post<Response<Catalog>>(
        `${apiConfig.url}/catalogs`,
        {
          ...catalogData,
          limits: catalogData.limits.map(limit => ({
            ...limit,
            category_ids: limit.categories.map(c => c.id),
          })),
        },
      );

      const { data } = res.data;

      dispatch<CatalogCreateActionSuccess>({
        type: ActionTypes.CATALOG_CREATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Catalog successfully created', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const errors = e.response?.data.errors;

        if (errors) {
          dispatch<CatalogCreateActionFail>({
            type: ActionTypes.CATALOG_CREATE_FAIL,
            payload: errors,
          });
        }
      }

      enqueueSnackbar('Validation error', {
        variant: 'error',
      });
    }
  };

export const createCatalogReset = (): CatalogCreateActionReset => ({
  type: ActionTypes.CATALOG_CREATE_RESET,
});
