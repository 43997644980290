import { z } from 'zod';

export const accountTypeEnum = z.enum([
  'live',
  'onboarding',
  'client_trial',
  'sales_demo',
  'to_be_deleted',
]);

export const employerStatsValidator = z.object({
  account_type: accountTypeEnum,
  orders_count: z.number(),
  repairs_count: z.number(),
  offboardings_count: z.number(),
  active_users_count: z.number(),
  total_users: z.number(),
  admins_count: z.number(),
  teams_count: z.number(),
  sso_enabled: z.boolean(),
  hr_sync_enabled: z.boolean(),
  slack_integration_enabled: z.boolean(),
  warehouse_assets_count: z.number(),
  warehouses_used_count: z.number(),
});
